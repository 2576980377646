import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import apiCallManager from '../../common/helper.js';
import { apiBaseUrl, currency, frontUrl } from '../../common/config.js';
import _HeartComponent from '../../common/_HeartComponent';

const MusicTab = (props) => {

    // const [produchList, setProduchList] = useState(props.produchList);

    const setIMg = (img, flag) => {
        try {
            // ////console.log("imgg", img)
            if (img && img.includes(flag)) {
                var imgg = img.split('/').pop();
                return frontUrl + imgg;
            } else if (img && img != null && img != 'null') {
                return frontUrl + img;
            } else {
                return apiBaseUrl + '/public/no-image.jpg';
            }
        } catch (error) {

        }
    }

    const clickGigLinkClick = () => {
        console.log("******", "clickGigLinkClick")
        props.clickGigLinkClick()
    }

    return (
        <>
            <a target="_blank" href={props.item.link} onClick={() => { clickGigLinkClick.bind(this) }} className="tab-gig-item" >
                <div className="tab-gig-item-left">
                    <div className="tab-gig-icon">
                        {
                            (props.item.id == 'spotify') ?
                                <img src="/images/play-icon.svg" />
                                :
                                (props.item.id == 'apple') ?
                                    <img src="/images/play-icon.svg" />
                                    :
                                    (props.item.id == 'tidal') ?
                                        <img src="/images/play-icon.svg" />
                                        :
                                        (props.item.id == 'youtube') ?
                                            <img src="/images/play-icon.svg" />
                                            :
                                            (props.item.id == 'amazon') ?
                                                <img src="/images/play-icon.svg" />
                                                :
                                                (props.item.id == 'ticket-master') ?
                                                    <img src="/images/t2.svg" width="33" />
                                                    :
                                                    (props.item.id == 'live_nation') ?
                                                        <img src="/images/t2.svg" width="33" />
                                                        :
                                                        (props.item.id == 'dice') ?
                                                            <img src="/images/t2.svg" width="33" />
                                                            :
                                                            (props.item.id == 'festicket') ?
                                                                <img src="/images/t2.svg" width="33" />
                                                                :
                                                                (props.item.id == 'Stubhub') ?
                                                                    <img src="/images/t2.svg" width="33" />
                                                                    :
                                                                    (props.item.id == 'google_play') ?
                                                                        <img src="/images/play-icon.svg" />
                                                                        :
                                                                        (props.item.id == 'itunes_store') ?
                                                                            <img src="/images/play-icon.svg" />
                                                                            :
                                                                            (props.item.id == 'deezer') ?
                                                                                <img src="/images/play-icon.svg" />
                                                                                :
                                                                                (props.item.id == 'skiddle') ?
                                                                                    <img src="/images/t2.svg" width="33" />
                                                                                    :
                                                                                    null
                        }

                    </div>
                </div>
                <div className="tab-gig-item-mid" title={props.item?.gigs_desc}>
                    {props.item?.gigs_desc}
                </div>
                <div className="tab-gig-item-right">

                    {
                        (props.item.id == 'spotify') ?
                            <div className="tab-gig-item-right-music">
                                <span>Listen on</span>
                                <img src="/images/gig-spotifiy.svg" />
                            </div>
                            :
                            (props.item.id == 'apple') ?
                                <div className="tab-gig-item-right-music">
                                    <span>Listen on</span>
                                    <img src="/images/gig-apple-music.svg" />
                                </div>
                                :
                                (props.item.id == 'tidal') ?
                                    <div className="tab-gig-item-right-music">
                                        <span>Listen on</span>
                                        <img src="/images/gig-tidal.svg" />
                                    </div>
                                    :
                                    (props.item.id == 'youtube') ?
                                        <div className="tab-gig-item-right-music">
                                            <span>Listen on</span>
                                            <img src="/images/gig-youtube.svg" />
                                        </div>
                                        :
                                        (props.item.id == 'amazon') ?
                                            <div className="tab-gig-item-right-music">
                                                <span>Listen on</span>
                                                <img src="/images/gig-amazon.svg" />
                                            </div>
                                            :
                                            (props.item.id == 'ticket-master') ?
                                                <div className="tab-gig-item-right-music">
                                                    <span>Purchase on</span>
                                                    <img src="/images/ticketmaster-ar21.svg" />
                                                </div>
                                                :
                                                (props.item.id == 'live_nation') ?
                                                    <div className="tab-gig-item-right-music">
                                                        <span>Purchase on</span>
                                                        <img src="/images/LN_NewLogo_FanMan-copy-2.svg" />
                                                    </div>
                                                    :
                                                    (props.item.id == 'dice') ?
                                                        <div className="tab-gig-item-right-music">
                                                            <span>Purchase on</span>
                                                            <img src="/images/dice_logo.svg" />
                                                        </div>
                                                        :
                                                        (props.item.id == 'festicket') ?
                                                            <div className="tab-gig-item-right-music">
                                                                <span>Purchase on</span>
                                                                <img src="/images/Festicket.svg" />
                                                            </div>
                                                            :
                                                            (props.item.id == 'Stubhub') ?
                                                                <div className="tab-gig-item-right-music">
                                                                    <span>Purchase on</span>
                                                                    <img src="/images/Stubhub.svg" />
                                                                </div>
                                                                :
                                                                (props.item.id == 'google_play') ?
                                                                    <div className="tab-gig-item-right-music">
                                                                        <span>Listen on</span>
                                                                        <img src="/images/google-play-4.svg" />
                                                                    </div>
                                                                    :
                                                                    (props.item.id == 'itunes_store') ?
                                                                        <div className="tab-gig-item-right-music">
                                                                            <span>Listen on</span>
                                                                            <img src="/images/iTunesStore.svg" />
                                                                        </div>
                                                                        :
                                                                        (props.item.id == 'deezer') ?
                                                                            <div className="tab-gig-item-right-music">
                                                                                <span>Listen on</span>
                                                                                <img src="/images/Deezer_logo.svg" />
                                                                            </div>
                                                                            :
                                                                            (props.item.id == 'skiddle') ?
                                                                            <div className="tab-gig-item-right-music">
                                                                                <span>Purchase on</span>
                                                                                <img src="/images/skiddle.png" height={20} width={48} />
                                                                            </div>
                                                                            :
                                                                            null
                    }


                </div>
            </a>
        </>
    )
}

/**
 * Connection with Redux
 */
// const mapStateToProps = state => ({
//     myname: state.myname
// });

// const mapDispatchToProps = dispatch => ({
//     addName: (data) => dispatch(addName(data)),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Favourite);

export default MusicTab;