import React, { useState, useEffect, useRef } from 'react';
import { apiBaseUrl, currency, frontUrl } from '../../common/config.js';
import _HeartComponent from '../../common/_HeartComponent';
import ImagePlaceholder from '../../common/ImagePlaceholder';

const ProductTab = (props) => {

    // const [produchList, setProduchList] = useState(props.produchList);

    const setIMg = (img, flag) => {
        try {
            // ////console.log("imgg", img)
            if (img && img.includes(flag)) {
                var imgg = img.split('/').pop();
                return frontUrl + imgg;
            } else if (img && img != null && img != 'null') {
                return frontUrl + img;
            } else {
                return apiBaseUrl + '/public/no-image.jpg';
            }
        } catch (error) {

        }
    }

    const showProdDetailPop = (flag, item, idx) => {
        console.log("******", "showProdDetailPop")
        props.showProdDetailPop(flag, item, idx)
    }

    const makeFavFn = (id, vid, flag, idx) => {
        console.log("******", "makeFavFn")
        props.makeFavFn(id, vid, flag, idx)
    }
   const isZero=(value) =>{
        // Convert the value to a number and check if it's exactly 0
        return Number(value) === 0;
      }
    return (
        <>
            <div className="tab-product-item" id={"tab-product-item-" + props.item._id}>
                {/* <img onClick={() => { showProdDetailPop(true, props.item, props.idpx) }} className="tab-product-item-mainimg" src={setIMg(props.item.product_image, 'product_image')} style={{ "maxWidth": "85px" }} />
                <div className="product-placeholder"></div> */}


                <ImagePlaceholder classdata={'tab-product-item-mainimg'} product_image={props.item.product_image} image_type={'product_image'} showProdDetailPop={showProdDetailPop.bind()} item={props.item} idx={props.idpx} placeholderClass={'product-placeholder'} style_data={{"maxWidth": "85px"}} placeholder_image={`${frontUrl}/product-placeholder.jpg`}/>


                <div className="tab-product-item-details">
                    <div className="tab-product-item-details-inner">
                        <div className="tab-product-item-time" onClick={() => { showProdDetailPop(true, props.item, props.idpx) }}></div>
                        <div className="tab-product-item-title" onClick={() => { showProdDetailPop(true, props.item, props.idpx) }}>{props.item.product_name}</div>
                        <div className="tab-product-item-logo" onClick={() => { showProdDetailPop(true, props.item, props.idpx) }}>
                            {
                                (props.item.product_brand && props.item.product_brand.image) ?
                                    <ImagePlaceholder product_image={props.item.product_brand.image} image_type={'brand_image'} placeholderClass={'logo-placeholder'} placeholder_image={`${frontUrl}logo-placeholder.jpg`}/>
                                    :
                                    <div className="logo-placeholder logo-placeholder-abs"></div>
                            }

                            {/* {
                                (props.item.product_brand && props.item.product_brand.image) ?
                                    <img src={setIMg(props.item.product_brand.image, 'brand_image')} />
                                    :
                                    <img src="/images/product-logo.png" />
                            } */}
                        </div>
                        {/* <div className="logo-placeholder"></div> */}
                        <div className="tab-product-item-infor">
                            {/* {(props.item.fev_data && props.item.fev_data.length > 0)? 1 : 0} */}
                            {/*<div className="tab-product-item-fav">*/}
                                {
                                    // (props.item.fev_data && props.item.fev_data.length > 0) ?
                                    //     <a onClick={() => { makeFavFn(props.item._id, props.videoId, false, props.idpx) }}>
                                    //         <img src="/images/favorite-fill-icon.svg" />
                                    //     </a>
                                    //     :
                                    //     <a onClick={() => { makeFavFn(props.item._id, props.videoId, true, props.idpx) }}>
                                    //         <img src="/images/favorite-icon.svg" />
                                    //     </a>
                                }
                            {/*</div>*/}
                            {
                                (props.item.currency == 'POA') ?
                                    <div onClick={() => { showProdDetailPop(true, props.item, props.idpx) }} className="tab-product-item-price"><span>{props.item.currency}</span></div>
                                    :
                                    <div onClick={() => { showProdDetailPop(true, props.item, props.idpx) }} className="tab-product-item-price"><span>{props.item.currency}</span>{!isZero(props.item.price)?props.item.price:''}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

/**
 * Connection with Redux
 */
// const mapStateToProps = state => ({
//     myname: state.myname
// });

// const mapDispatchToProps = dispatch => ({
//     addName: (data) => dispatch(addName(data)),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Favourite);

export default ProductTab;
