const promises = {
    init: () => {
        return new Promise((resolve, reject) => {
            try {
                if (typeof FB !== 'undefined') {
                    resolve();
                } else {

                    window.fbAsyncInit = () => {
                        window.FB.init({
                            appId: '358138715452465',
                            cookie: true,
                            xfbml: true,
                            version: 'v8.0'
                        });
                        window.FB.AppEvents.logPageView();
                        resolve();
                    };
                    (function(d, s, id) {
                        var js, fjs = d.getElementsByTagName(s)[0];
                        if (d.getElementById(id)) return;
                        js = d.createElement(s);
                        js.id = id;
                        js.src = "//connect.facebook.net/en_US/sdk.js";
                        fjs.parentNode.insertBefore(js, fjs);
                    }(document, 'script', 'facebook-jssdk'));




                    // window.fbAsyncInit = function() {
                    //     window.FB.init({
                    //         appId: '358138715452465',
                    //         cookie: true, // enable cookies to allow the server to access
                    //         xfbml: true, // parse social plugins on this page
                    //         version: 'v8.0' // use version 2.1
                    //     });

                    //     window.FB.AppEvents.logPageView();
                    //     window.FB.getLoginStatus(function(response) {
                    //         this.statusChangeCallback(response);
                    //     }.bind(this));
                    // }.bind(this);

                    // // Load the SDK asynchronously
                    // (function(d, s, id) {
                    //     var js, fjs = d.getElementsByTagName(s)[0];
                    //     if (d.getElementById(id)) { return; }
                    //     js = d.createElement(s);
                    //     js.id = id;
                    //     js.src = "https://connect.facebook.net/en_US/sdk.js";
                    //     fjs.parentNode.insertBefore(js, fjs);
                    // }(document, 'script', 'facebook-jssdk'));
                }
            } catch (error) {

            }
        });
    },
    checkLoginState: () => {
        return new Promise((resolve, reject) => {
            window.FB.getLoginStatus((response) => {
                //console.log("checkLoginState***", response)
                response.status === 'connected' ? resolve(response) : reject(response);
            });
        });
    },
    login: () => {
        return new Promise((resolve, reject) => {
            window.FB.login((response) => {
                //console.log("login***", response)
                if(response.status === 'connected'){                    
                    resolve(response);
                }else{
                    reject(response);
                }
            },{scope: 'email,public_profile'});
        });
    },
    logout: () => {
        return new Promise((resolve, reject) => {
            window.FB.logout((response) => {
                //console.log("logout***", response)
                response.authResponse ? resolve(response) : reject(response);
            });
        });
    },
    fetch: () => {
        return new Promise((resolve, reject) => {
            window.FB.api(
                '/me', { fields: 'email,picture,birthday,location,locale,age_range,currency,first_name,last_name,gender' },
                response => response.error ? reject(response) : resolve(response)
            );
        });
    },
    profile_photo: (id) => {
        return new Promise((resolve, reject) => {
            // console.log("id", id)
            window.FB.api("/166995738421801/picture",
                'GET',
                {"type":"large"},
                function(response) {
                    // console.log("response*****", response)
                    resolve(response)
                }
            );
        });
    }
}

export const Facebook = {
    doLogin(fn) {
        try {
            promises.init()
                .then(
                    promises.checkLoginState,
                    error => { throw error; }
                )
                .then(
                    response => { console.log({ status: response.status }); },
                    promises.login                
                )
                .then(
                    promises.fetch,
                    error => { throw error; }
                )
                .then(
                    response => { 
                        var dataObj = { loading: false, data: response, status: true }
                        fn(dataObj)
                    },
                    error => { throw error; }
                )
                .catch((error) => {
                    var dataObj = { loading: false, data: {}, status: false }
                    fn(dataObj)
                });
        } catch (error) {

        }
    },
    doLogout() {
        try {
            promises.init()
                .then(
                    promises.checkLoginState,
                    error => { throw error; }
                )
                .then(
                    promises.logout,
                    error => { console.log({ data: {}, status: 'unknown' }); }
                )
                .then(
                    response => { console.log({ loading: false, data: {}, status: 'unknown' }); },
                    error => { throw error; }
                )
                .catch(error => {
                    console.log({ loading: false, data: {}, status: 'unknown' });
                    console.warn(error);
                });
        } catch (error) {

        }
    },
    checkStatus() {
        try {
            promises.init()
                .then(
                    promises.checkLoginState,
                    error => { throw error; }
                )
                .then(
                    response => { console.log({ status: response.status }); },
                    error => { throw error; }
                )
                .then(
                    promises.fetchUser,
                    error => { throw error; }
                )
                .then(
                    response => { console.log({ loading: false, data: response, status: 'connected' }); },
                    error => { throw error; }
                )
                .catch((error) => {
                    //console.log({ loading: false, data: {}, status: 'unknown' });
                    console.warn(error);
                });
        } catch (error) {

        }
    }
};
