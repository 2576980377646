import React from 'react'

function Footer (){ 
    return(
        <div style={{textAlign:"center", paddingTop:'50px', display: 'none'}}>
            <h2>Footer</h2>
        </div>
    )
}

export default Footer;