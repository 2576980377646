import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import "@flowplayer/player/flowplayer.css"
import apiCallManager from '../../common/helper.js';
import { apiBaseUrl, currency, frontUrl } from '../../common/config.js';
import ImagePlaceholder from '../../common/ImagePlaceholder';
import { isEmpty, capitalize, findIndex, forEach, orderBy } from 'lodash';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import { setAuth } from "../../redux/action/index";
import _HeartComponent from '../../common/_HeartComponent';
import _Header from './header';
import ProductTab from './ProductTab';
import PeopleTab from './PeopleTab';
import MusicTab from './MusicTab';
import InspirationTab from './InspirationTab';
import SimpleReactValidator from 'simple-react-validator';
import { ToastContainer, toast } from 'react-toastify';
import { Facebook } from '../../common/_facebook.js';
import Flowplayer from "@flowplayer/react-flowplayer";
import { useSelector, useDispatch } from 'react-redux';
// import { setAuthUser, setLoggedIn, setQuoteData } from '../../store';
import HLSPlugin from "@flowplayer/player/plugins/hls";
import CUEPOINTSPlugin from "@flowplayer/player/plugins/cuepoints";
import QSELPlugin from "@flowplayer/player/plugins/qsel";
import flowplayer from "@flowplayer/player";
import { useFlowplayer } from "@flowplayer/react-flowplayer";
import { PAUSE, PLAYING, CUEPOINT_START, FULLSCREEN_ENTER, TIME_UPDATE, SEEKED } from "@flowplayer/player/core/events"
flowplayer(HLSPlugin, CUEPOINTSPlugin, QSELPlugin)


var timepLay = true;
var _timeout = null;
var fullAddress = {};
var scrollStatus = "scroll_stopped";
const DEMO_TOKEN = "eyJraWQiOiJ0SDliM1c1VE4wQ3oiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NixcImlkXCI6XCJ0SDliM1c1VE4wQ3pcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.n3kqcvzPoszGAUcCK4USJYv_imVIoJ27EHzY45ScvUjo9aCWEEHciisZNe4moYmS1AO_0RoDqo_3QsIJas00Ow";



const Video = (props) => {

    const { Flowplayer, api: playerApi } = useFlowplayer({ token: DEMO_TOKEN });
    const dispatch = useDispatch();
    const messagesEnd = useRef(null);
    const validator = useRef(new SimpleReactValidator())
    const [cuepointsArray, setcuepointsArray] = useState([]);
    const [videoPoster, setvideoPoster] = useState("");
    const [videoUrl, setvideoUrl] = useState("");
    const [vtrans, setvtrans] = useState(400);
    const [videoDetail, setvideoDetail] = useState({});
    const [produchList, setProduchList] = useState([]);
    const [peropleList, setperopleList] = useState([]);
    const [gigsUrls, setgigsUrls] = useState([]);
    const [inspirationsList, setinspirationsList] = useState([]);
    // const [scrollStatus, setscrollStatus] = useState("scroll_stopped");
    const [activeTab, setactiveTab] = useState("tab1");
    const [videoId, setvideoId] = useState("");
    const [showProdDetail, setshowProdDetail] = useState(false);
    const [prodData, setprodData] = useState({});
    const [prodIdx, setprodIdx] = useState(null);

    const [total_views, settotalViews] = useState(0);
    const [openVidDesc, setopenVidDesc] = useState(false);
    const [prodMoreDetail, setprodMoreDetail] = useState(false);
    const [fullDesc, setfullDesc] = useState("");
    const [fullDescActive, setfullDescActive] = useState(false);
    const [vidfullDesc, setvidfullDesc] = useState("");
    const [vidfullDescActive, setvidfullDescActive] = useState(false);
    // const [fullAddress, setfullAddress] = useState({});
    const [showLoader, setshowLoader] = useState(false);
    const [formValid, setformValid] = useState(false);
    const [errorCount, seterrorCount] = useState(null);
    const [heartIndex, setheartIndex] = useState(null);

    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [fullname, setfullname] = useState("");
    const [phone, setphone] = useState("");
    const [totalInteraction, settotalInteraction] = useState(0);
    const [showPop, setshowPop] = useState(false);
    const [showViktrs, setshowViktrs] = useState(true);
    const [isPublish, setisPublish] = useState(false);
    const [openLoginPop, setopenLoginPop] = useState(false);
    const [openSignUpPop, setopenSignUpPop] = useState(false);
    const [openForgotPop, setopenForgotPop] = useState(false);
    const [tokenstr, settokenstr] = useState(null);
    const [favproduchList, setfavproduchList] = useState([]);
    const [showFevLoader, setshowFevLoader] = useState(false);
    const [page, setpage] = useState(1);
    const [pagesize, setpagesize] = useState(20);
    const [produchListBack, setproduchListBack] = useState([]);
    const [produchListBackMain, setproduchListBackMain] = useState([]);
    // const [prodIndx, setprodIndx] = useState(null);
    const [values, setValues] = React.useState({
        email: '',
        password: '',
        phone: '',
        fullname: ''
    });
    const [errors, seterrors] = useState({ email: '', password: '', fullname: '', phone: '' });

    useEffect(() => {
        if (!playerApi) return
        setvideoId(props.match.params.id)
        const tokens = localStorage.getItem('token');
        const clickpopStatus = localStorage.getItem('click_popup');
        if (tokens && !clickpopStatus || clickpopStatus === false || clickpopStatus == 'false') {
            setshowPop(true)
        }

        //console.log("props.match.params.id", props.match.params.id)
        // $('#intiFlowplayer').trigger('click')
        getVideoDetail();
        settokenstr(tokens)
    }, [playerApi])


    const intiFlowplayer = () => {
        try {
            // //console.log("flowplayer", flowplayer)
            getVideoDetail();
        } catch (error) {
            //console.log("error", error)
        }
    }

    const destroyplayerdd = () => {
        try {
            // //console.log("events", playerApi.events)
            //console.log("flowPlayerEve---------------*", produchList)
            playerApi.destroy();
        } catch (error) {
            //console.log("destroyplayerdd error", error)
        }
    }

    const makeLandscape = () => {
        try {
            //////console.log("window.screen",window.screen)
            //this.player.setFullscreenControlOrientation(false)
        } catch (error) {
            //////console.log("error", error)   
        }
    }


    const getVideoDetail = async () => {
        try {
            getCurrentLocation();
            var dataObj = {};
            const subreddit = $("#viktrsid").attr('data-id')
            const token = localStorage.getItem('token');
            if (token) {
                dataObj = {
                    id: props.match.params.id,
                    is_from: true
                }
                dataObj = JSON.stringify(dataObj);
                var videoData = await apiCallManager.callApi('get_video_detail_auth', dataObj, 'POST', false, false);
            } else {
                var ids = props.match.params.id
                // var videoData = await getJsonData()
                var videoData = await apiCallManager.callApi('get_video_detail/' + ids + '/true', null, 'GET', true, false);
            }

            var totalObj = await apiCallManager.callApi('get_video_count', JSON.stringify({ id: props.match.params.id }), 'POST', false, false);

            // console.log("videoData", videoData)
            if (videoData.status === 1 && videoData.data && videoData.data._id) {
                var poster = videoData.data.video_obj.poster;
                var vidSrc = videoData.data.video_obj.m3u8_url || videoData.data.video_obj.src;
                //var timeArray = videoData.data.product_list;
                // ////console.log("videoData.data.product_list", videoData.data.product_list)
                var timeArrayLength = videoData.data.product_list.length;
                var timeArray = [];
                forEach(videoData.data.product_list, function (i, k) {
                    timeArray.push({
                        "start": i.start_time,
                        "end": i.end_time,
                        "start_time": i.start,
                        "end_time": i.end,
                        "product": i.product
                    })
                    if (k === (timeArrayLength - 1)) {
                        timeArray = orderBy(timeArray, ['start'], ['asc']);

                    }
                })

                // //console.log("timeArray", timeArray) 
                // var config = {
                //     muted : false,
                //     token: flowToken,
                //     poster: poster,
                //     src: vidSrc,
                //     autoplay: true,
                //     ratio: '960:400',
                //     cuepoints: timeArray,
                //     draw_cuepoints: true,
                //     mute: false,                    
                //     qsel : {}
                // }

                // console.log("config****", totalObj, videoData)

                var totalInteraction = totalObj.intractions;

                // var totalInteraction = kFormatter(videoData.data.product_click + videoData.data.tab_click_count + videoData.data.product_impression + videoData.data.gig_and_music_link_click + videoData.data.people_click + videoData.data.product_back_button_click + videoData.data.product_shop_btn_click + videoData.data.inspiration_link_click + videoData.data.favourites_click)

                // //console.log("videoData.data.product_click", videoData.data.product_click)
                // //console.log("videoData.data.tab_click_count", videoData.data.tab_click_count)
                // //console.log("videoData.data.product_impression", videoData.data.product_impression)
                // //console.log("videoData.data.gig_and_music_link_click", videoData.data.gig_and_music_link_click)
                // //console.log("videoData.data.people_click", videoData.data.people_click)
                // //console.log("videoData.data.product_back_button_click", videoData.data.product_back_button_click)
                // //console.log("videoData.data.product_shop_btn_click", videoData.data.product_shop_btn_click)
                // //console.log("videoData.data.inspiration_link_click", videoData.data.inspiration_link_click)
                // //console.log("videoData.data.favourites_click", videoData.data.favourites_click)

                // console.log("videoData", videoData)

                setcuepointsArray(timeArray);
                setvideoPoster(poster);
                setvideoUrl(vidSrc);
                setshowViktrs(true);
                setisPublish(false);
                settotalInteraction((totalInteraction) ? totalInteraction : 0);
                setvideoDetail(videoData.data);
                setperopleList(videoData.data.peoples);
                setgigsUrls(videoData.data.gigs);
                setProduchList(videoData.prodlist);
                setinspirationsList(videoData.data.inspirations_data);
                settotalViews(totalObj.total_views);
                flowPlayerEvent(videoData.prodlist);

                // setTimeout(function(){
                //     flowPlayerEvent();
                // }, 1500);

            } else if (videoData.status === 401) {
                //this.props.history.push(`/login`)
                setshowViktrs(false);
                setisPublish(true);
            } else {
                setshowViktrs(false);
                setisPublish(true);
            }
        } catch (error) {
            ////////console.log("error", error)
        }
    }

    const getJsonData = async () => {
        try {
            let response;
            let responseJSON;
            return new Promise(async (resolve, reject) => {
                response = await fetch("../../../data.json", {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                });
                responseJSON = await response.json();

                if (responseJSON.data) {
                    var resData = await apiCallManager.checkEncryption(responseJSON.data);
                    responseJSON.data = resData;
                }

                if (responseJSON.prodlist) {
                    var prodlist = await apiCallManager.checkEncryption(responseJSON.prodlist);
                    responseJSON.prodlist = prodlist;
                }

                resolve(responseJSON)
            });
        } catch (error) {

        }

    }

    const flowPlayerEvent = (produchDataList) => {
        try {


            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);

            // setTimeout(function () {
            playerApi.on(CUEPOINT_START, function (e) {
                console.log("flowPlayerEvent**********", scrollStatus)
                myFunction(e, produchDataList)
            });

            playerApi.on(FULLSCREEN_ENTER, function (e) {
                //////console.log("e***000-FULLSCREEN_ENTER", e)
                makeLandscape();
            });

            playerApi.on(TIME_UPDATE, function (e) {
                // if(e.srcElement.currentTime )
                var ceil = Math.ceil(e.srcElement.currentTime);
                if (ceil === 5 && timepLay) {
                    addViews();
                    timepLay = false;
                }
            });

            playerApi.on(SEEKED, function (e) {
                ////console.log("e", e)
                var ceil = Math.ceil(e.srcElement.currentTime);
                if (ceil < 5) {
                    timepLay = true;
                }
            });
            // }, 100)

        } catch (error) {
            //console.log("flowPlayerEvent error", error)
        }
    }

    const kFormatter = (labelValue) => {
        return labelValue;
        return false;
        return Math.abs(Number(labelValue)) >= 1.0e+9

            ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
            // Six Zeroes for Millions 
            : Math.abs(Number(labelValue)) >= 1.0e+6

                ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
                // Three Zeroes for Thousands
                : Math.abs(Number(labelValue)) >= 1.0e+3

                    ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"

                    : Math.abs(Number(labelValue));
    }

    const myFunction = (e, produchDataList) => {
        // //console.log('112121', e.data.cuepoint.product, produchDataList)
        try {
            var currentTime = '#tab-product-item-' + e.data.cuepoint.product;
            //console.log("scrollStatus", scrollStatus)
            if (scrollStatus == 'scroll_stopped' && activeTab == 'tab1') {
                var prodId = e.data.cuepoint.product;
                var index = findIndex(produchDataList, { _id: prodId })

                $('.tab-product-item').removeClass('active');
                $(currentTime).addClass('active');
                var top = index * 99;
                //console.log("top***********", index, top, currentTime)
                $(".tab-product-wrapper").animate({ scrollTop: top }, vtrans);
                saveAnalyticData(null, 'product_impression');
            } else {
                ////////console.log("currentTime", currentTime)
            }
        } catch (error) {
            //console.log("error", error)
        }
    }

    const listenScrollEvent = () => {
        try {
            // console.log("scrollStatus", scrollStatus)
            if (_timeout) { //if there is already a timeout in process cancel it
                clearTimeout(_timeout);
            }
            _timeout = setTimeout(() => {
                _timeout = null;
                // setscrollStatus('scroll_stopped')
                scrollStatus = "scroll_stopped";
            }, 500);
            if (scrollStatus !== 'scrolling') {
                //console.log('12121212')
                // setscrollStatus('scrolling')
                scrollStatus = "scrolling";
            }
        } catch (error) {
            //console.log("error", error)
        }
    }

    const getCurrentLocation = async () => {
        try {
            fullAddress = await apiCallManager.getIpAddr();
            // setfullAddress(locaation)
        } catch (error) {

        }
    }

    const dotText = (str, num) => {
        try {
            if (str) {
                return str.substring(0, num) + '...';
            }
        } catch (error) {
            ////////console.log("error", error)
        }
    }

    const Capitalize = (str, num) => {
        try {
            if (str) {
                return str //str.charAt(0).toUpperCase() + str.slice(1);
            } else {
                return null
            }
        } catch (error) {

        }
    }

    const tabCLick = (tab, type) => {
        try {
            setactiveTab(tab)

            $('.viktrs-tabs').hide();
            $('#tab-dis-' + tab).show();
            saveAnalyticData(null, type, true);
        } catch (error) {

        }
    }

    const addViews = async () => {
        try {
            var dataObj = {
                vid: props.match.params.id,
                uid: localStorage.getItem('id'),
                city: fullAddress.city || null,
                region: fullAddress.region || null,
                country: fullAddress.country_name || null,
                ip: fullAddress.ip || null,
                full_address: fullAddress,
            }

            dataObj = JSON.stringify(dataObj);
            // this.setState({
            //     total_views : total_views+1
            // });
            ////////console.log("dataObj", dataObj)
            var res = await apiCallManager.callApi('add_views', dataObj, 'POST', false, false);
            ////////console.log("res", res)
            if (res.status === 1) { } else {
                props.alert.error(res.message)
            }
        } catch (error) {

        }
    }

    const makeFav = (item, index) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                makeFavFn(item, index, true)
            } else {
                props.history.push(`/login`)
            }
        } catch (error) {

        }
    }

    const onPressFav = (flag, pid, index) => {
        try {
            //console.log(flag, pid, index)
            if (flag === 'login') {
                var vid = props.match.params.id
                var prevPath = window.location.pathname;
                localStorage.setItem('priv_path', prevPath);
                localStorage.setItem('pid', pid);
                localStorage.setItem('vid', vid);
                setopenLoginPop(true);
                setprodIdx(index);
                saveAnalyticData(null, "favourites_click")
                //this.props.history.push(`/login`);
            }
        } catch (error) {

        }
    }

    const onResponseCallback = (flag, index) => {
        try {
            //console.log("flag****", flag, index)
            produchList[index]['fev_data'] = (flag) ? ['true'] : null;
            saveAnalyticData(null, "favourites_click")
        } catch (error) {

        }
    }

    const showProdDetailPop = (flag, item, index) => {
        try {

            console.log('33333333333', flag, index)
            if (index >= 0) {
                saveAnalyticData(item, "product_click")
            }

            if (flag === false) {
                saveAnalyticData(item, "product_back_button_click")
            }

            item['is_fav'] = (item.fev_data && item.fev_data.length > 0) ? true : false;

            setshowProdDetail(flag)
            setprodData(item)
            setprodIdx(index)

            //console.log("flag", flag, item, index)

        } catch (error) {
            ////////console.log("error", error)
        }
    }

    const goToProductDetailPage = (item) => {
        try {
            ////////console.log("item", item)
            //return <Redirect to={{pathname: 'video'}} />
            // this.props.history.push(`/product/${videoId}/${prodData._id}`);
            //this.props.history.push(`/product/${prodData._id}/${videoId}`);

        } catch (error) {
            ////////console.log("error", error)
        }
    }

    const openVideoDesc = () => {
        try {
            //openVidDesc = !openVidDesc
            setopenVidDesc(!openVidDesc)

        } catch (error) {

        }
    }

    const openMoreDetail = () => {
        try {

            var prodMoreDetailFlag = !prodMoreDetail
            setprodMoreDetail(prodMoreDetailFlag)
            if (prodMoreDetailFlag) {
                scrollToBottom();
            }

        } catch (error) {

        }
    }

    const scrollToBottom = () => {
        try {
            messagesEnd.scrollIntoView({ behavior: "smooth" });
        } catch (error) {

        }
    }

    const covertimeWithColon = (time) => {
        try {
            if (time && time.length > 4) {
                return time.replace(/(..)/g, '$1:').slice(0, -1);
            } else {
                return "";
            }

        } catch (error) {

        }
    }

    const clickShowMore = (desc) => {
        try {
            setfullDescActive(true)
            setfullDesc(desc)
        } catch (error) {

        }
    }

    const closeShowMore = (id) => {
        try {
            setfullDescActive(false);
        } catch (error) {

        }
    }

    const checkSeeMore = (desc, length) => {
        try {
            // //////console.log("desc", desc)
            if (desc && desc.length > length) {
                return desc.substring(0, length);
            } else {
                return desc
            }

        } catch (error) {

        }
    }

    const videDescSeeMore = (web) => {
        try {
            setvidfullDescActive(web)
        } catch (error) {

        }
    }

    const setIMg = (img, flag) => {
        try {
            // ////console.log("imgg", img)
            if (img && img.includes(flag)) {
                var imgg = img.split('/').pop();
                return frontUrl + imgg;
            } else if (img && img != null && img != 'null') {
                return frontUrl + img;
            } else {
                return apiBaseUrl + '/public/no-image.jpg';
            }
        } catch (error) {

        }
    }

    const closePop = async () => {
        try {
            setshowPop(false)

            var dataObj = JSON.stringify({ click_popup: true });
            var res = await apiCallManager.callApi('update_user', dataObj, 'POST', false, false);

            if (res.status === 1) {
                localStorage.setItem('click_popup', true);
            }
        } catch (error) {

        }
    }

    const goToHome = async () => {
        props.history.push(`/`)
    }

    const saveAnalyticData = async (item, type, tab) => {
        try {
            var insight_type = {};
            var num = (type == 'product_shop_btn_click' || type == 'favourites_click') ? 2 : 1;
            if (tab) {
                insight_type["tab_click_count"] = num;
            } else {
                insight_type[type] = num;
            }
            var prodId = (item && item._id) ? item._id : null;
            var uid = localStorage.getItem('id') || null;
            var dataObj = {
                video_id: props.match.params.id,
                product_id: prodId,
                user_id: uid,
                type: type,
                city: fullAddress.city || null,
                region: fullAddress.region || null,
                country: fullAddress.country_name || null,
                ip: fullAddress.ip || null,
                full_address: fullAddress,
                insight_type
            };
            dataObj = JSON.stringify(dataObj);
            var res = await apiCallManager.callApi('add_analytics_data', dataObj, 'POST', true, false);
            if (res.status === 1) {

            } else {
                props.alert.error(res.message)
            }

        } catch (error) {
            console.log("error", error)
        }
    }

    const goToRegister = () => {
        try {
            setopenLoginPop(false);
            setopenSignUpPop(true);
            validator.current.hideMessages();
        } catch (error) {
            console.log("error", error)
        }
    }

    const backToLogin = () => {
        try {
            setopenLoginPop(true)
            setopenSignUpPop(false)
            setopenForgotPop(false)
            validator.current.hideMessages();
        } catch (error) {
            console.log("error11", error)
        }
    }
    const isZero=(value) =>{
        // Convert the value to a number and check if it's exactly 0
        return Number(value) === 0;
      }
    const makeFavFn = async (pid, vid, fav_status, index) => {
        var self = this;
        try {
            var indx = 0;
            console.log("pid, vid, fav_status, index*****", index, heartIndex, prodIdx)
            if (heartIndex || heartIndex == 0) {
                indx = heartIndex
            } else if (prodIdx || prodIdx == 0) {
                indx = prodIdx
            } else if (index || index == 0) {
                indx = index
            }

            console.log("index***", indx)

            const token = localStorage.getItem('token');
            if (token) {

                var dataObj = {
                    //uid: id,
                    vid: vid,
                    pid: pid,
                    fav_status: fav_status
                }

                dataObj = JSON.stringify(dataObj);


                // return false

                var deepCopy = JSON.parse(JSON.stringify(produchList));

                var res = await apiCallManager.callApi('add_update_fav', dataObj, 'POST', false, false);
                saveAnalyticData(null, "favourites_click")
                if (res.status === 1) {
                    localStorage.removeItem('priv_path');
                    localStorage.removeItem('vid');
                    localStorage.removeItem('pid');
                    console.log("************************", fav_status)
                    if (fav_status) {
                        deepCopy[indx]['fev_data'] = [res.data];
                        deepCopy[indx]['is_fav'] = true;
                    } else {
                        deepCopy[indx]['fev_data'] = [];
                        deepCopy[indx]['is_fav'] = false;
                    }

                    var prodData = deepCopy[indx];

                    console.log("prodData", prodData)

                    setprodData(prodData);
                    setProduchList(deepCopy)
                    setprodIdx(null);
                } else {
                    toast.error(res.message)
                }
            } else {
                onPressFav('login', pid, indx);
            }

        } catch (error) {
            console.log("error", error)
        }
    }

    const removeFavFn = async (vid, pid, index) => {
        try {
            var dataObj = {
                //uid: id,
                vid: vid,
                pid: pid,
                fav_status: false
            }

            dataObj = JSON.stringify(dataObj);
            var indx = heartIndex || prodIdx || index;
            // //console.log("dataObj", dataObj, prodIdx)
            var deepCopy = JSON.parse(JSON.stringify(produchList));

            // //console.log("deepCopy[prodIdx]", deepCopy[prodIdx])
            // return false;

            var res = await apiCallManager.callApi('add_update_fav', dataObj, 'POST', false, false);
            if (res.status === 1) {
                deepCopy[prodIdx]['fev_data'] = [];
                deepCopy[prodIdx]['is_fav'] = false;
                var prodData = deepCopy[prodIdx];
                setprodData(prodData);
                setProduchList(deepCopy)
            } else {
                toast.error(res.message)
            }
        } catch (error) {
            //console.log("error", error)
        }
    }

    const closeAllPopup = () => {
        try {

            setopenLoginPop(false);
            setopenSignUpPop(false);
            setopenForgotPop(false);
            setshowPop(false);
            setprodIdx(null);
            setheartIndex(null);

        } catch (error) {

        }
    }

    // const handleChange = (event) => {
    //     try {
    //         // event.preventDefault();
    //         // const { name, value } = event.target;
    //         // this.setState({
    //         //     [name]: value });
    //     } catch (error) {
    //         //////console.log("error", error)
    //     }
    // }

    const handleChange = name => event => {
        //console.log("name", name)
        //console.log("value", event.target.value)
        setValues({ ...values, [name]: event.target.value });
    };

    const handleSubmitlogin = (event) => {
        try {
            event.preventDefault();
            //console.log("validator.allValid()", validator.current.allValid())
            if (validator.current.allValid()) {
                setshowLoader(true)
                initiateLogin();
            } else {
                validator.current.showMessages();
            }
        } catch (error) {
            //console.log("error", error)
        }
    }

    const handleSubmitregister = (event) => {
        try {
            event.preventDefault();

            if (validator.current.allValid()) {
                setshowLoader(true)
                initiateRegister();
            } else {
                validator.current.showMessages();
            }
        } catch (error) {
            //////console.log("error", error)
        }
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleSubmitforgot = (event) => {
        try {
            const { email } = values
            event.preventDefault();

            if (validateEmail(email)) {
                setshowLoader(true)
                initiateForgot();

            } else {
                validator.current.showMessages();
            }
        } catch (error) {
            //////console.log("error", error)
        }
    }

    const initiateLogin = async () => {
        try {
            const { email, password } = values;
            var dataObj = {
                email: email,
                password: password
            }

            dataObj = JSON.stringify(dataObj);

            // return false;

            var res = await apiCallManager.callApi('user_signin', dataObj, 'POST', true, false);
            setshowLoader(false);
            if (res.status === 1) {
                let loginData = res.data;
                console.log("prodIdx***", res)
                localStorage.setItem('token', loginData.access_token);
                localStorage.setItem('id', loginData._id);
                localStorage.setItem('photo', loginData.photo);
                localStorage.setItem('fullname', loginData.fullname);
                localStorage.setItem('click_popup', loginData.click_popup);

                const pid = produchList[prodIdx]['_id'];
                const vid = props.match.params.id;
                makeFavFn(pid, vid, true, prodIdx);
                closeAllPopup();
                getVideoDetail();
                settokenstr(loginData.access_token)
            } else {
                toast.error(res.message);
            }
        } catch (error) {
            console.log("error", error)
            setshowLoader(false);

        }
    }

    const initiateRegister = async () => {
        try {
            const { email, password, phone, fullname } = values;
            var dataObj = {
                email: email,
                password: password,
                // phone : phone,
                fullname: fullname,
                from_front: true
            }

            dataObj = JSON.stringify(dataObj);
            const priv_path = localStorage.getItem('priv_path');

            var dataRes = await apiCallManager.callApi('user_signup', dataObj, 'POST', true, false);
            setshowLoader(false);
            if (dataRes.status === 1) {
                // toast.success(dataRes.message);
                let loginData = dataRes.data;
                localStorage.setItem('token', loginData.access_token);
                localStorage.setItem('id', loginData._id);
                localStorage.setItem('photo', loginData.photo);
                localStorage.setItem('fullname', loginData.fullname);
                localStorage.setItem('click_popup', loginData.click_popup);
                closeAllPopup();
                const pid = produchList[prodIdx]['_id']
                const vid = props.match.params.id;
                makeFavFn(pid, vid, true, prodIdx);
                getVideoDetail();
                settokenstr(loginData.access_token)
            } else {
                toast.error(dataRes.message)
            }
        } catch (error) {
            console.log("error", error)
            setshowLoader(false);
        }
    }

    const initiateForgot = async () => {
        try {
            const { email } = values;
            var dataObj = {
                email: email
            }

            dataObj = JSON.stringify(dataObj);

            var res = await apiCallManager.callApi('user_forgot_password', dataObj, 'POST', true, false);
            setshowLoader(false);

            if (res.status === 1) {
                toast.success(res.message);

                setopenLoginPop(false);
                setopenSignUpPop(false)
                setopenForgotPop(false)
            } else {
                toast.error(res.message)
            }
        } catch (error) {
            setshowLoader(false);
        }
    }

    const clickForgetPass = () => {
        try {

            setopenLoginPop(false);
            setopenSignUpPop(false)
            setopenForgotPop(true)

        } catch (error) {

        }
    }

    const facebookLoginClick = () => {
        try {
            var self = this;
            Facebook.doLogin(function (res) {
                if (res.status) {
                    setshowLoader(true)
                    facebookLogin(res.data);
                }
            })
        } catch (error) {
            //console.log("error", error)
        }
    }

    const facebookLogin = async (fbres) => {
        try {
            var dataObj = {
                email: fbres.email,
                first_name: fbres.first_name,
                last_name: fbres.last_name,
                user_photo: fbres.picture.data.url || null,
                fb_id: fbres.id,
                login_type: "facebook",
                facebook_json: JSON.stringify(fbres)
            }

            dataObj = JSON.stringify(dataObj);
            const priv_path = localStorage.getItem('priv_path');

            var res = await apiCallManager.callApi('facebook_sign', dataObj, 'POST', true, false);
            if (res.status === 1) {
                let loginData = res.data;
                localStorage.setItem('token', loginData.access_token, { path: '/' });
                localStorage.setItem('id', loginData._id, { path: '/' });
                localStorage.setItem('photo', loginData.photo, { path: '/' });
                localStorage.setItem('fullname', loginData.fullname, { path: '/' });

                if (loginData.login_type) {
                    localStorage.setItem('login_type', loginData.login_type);
                }
                props.setAuth(true);

                if (priv_path) {
                    const pid = localStorage.getItem('pid');
                    const vid = localStorage.getItem('vid');
                    makeFavFn(pid, vid, true);
                    // this.props.history.push(priv_path)
                } else {
                    // this.props.history.push(`/`)
                }

                setshowLoader(false)

            } else {
                toast.error(res.message)
                setshowLoader(false)
            }
        } catch (error) {
            //console.log("error", error)
            setshowLoader(false)
        }
    }

    const createMarkup = (link) => {
        return { __html: link };
    }

    const clickPeopleClick = (item) => {
        try {

            if (item.link) {
                window.open(item.link, '_blank')
            } else {
                window.open('https://www.viktrs.com/', '_blank')
            }
            saveAnalyticData(null, 'people_click');
        } catch (error) {

        }
    }

    const clickGigLinkClick = () => {
        try {
            saveAnalyticData(null, 'gig_and_music_link_click');
        } catch (error) {

        }
    }

    const shopNowButtonClick = (pid) => {
        try {
            var item = { "_id": pid }
            saveAnalyticData(item, 'product_shop_btn_click');
        } catch (error) {

        }
    }

    const handlePaste = (e) => {
        try {

            var email = e.clipboardData.getData('Text');
            //////console.log("email***", email)

        } catch (error) {

        }

    }

    const clickLogout = () => {
        try {
            localStorage.removeItem('token', { path: '/' });
            localStorage.removeItem('id', { path: '/' });
            localStorage.removeItem('pid', { path: '/' });
            localStorage.removeItem('vid', { path: '/' });
            localStorage.removeItem('priv_path', { path: '/' });
            localStorage.removeItem('click_popup', { path: '/' });
            localStorage.removeItem('photo', { path: '/' });
            localStorage.removeItem('fullname', { path: '/' });
            // this.props.setAuth(false);
            // this.props.history.push(`/`)
            const login_type = localStorage.getItem('login_type');
            // console.log("login_type**", login_type)
            if (login_type && login_type == 'facebook') {
                Facebook.doLogout()
            }
            // alert('11')
            const token = localStorage.getItem('token');

            deleteAllCookies()
            // window.location.reload();
            // console.log("this.props.is_authincate", this.props.is_authincate, token)
            getVideoDetail();
            setprodIdx(null);
            setheartIndex(null);
        } catch (error) {
            console.log("error", error)
        }
    }

    const deleteAllCookies = () => {
        try {
            // var cookies = document.cookie.split(";");    
            const token = localStorage.getItem('token');
            localStorage.clear();
            settokenstr(null)

            setTimeout(function () {

                console.log("token", token)
            });
        } catch (error) {
            console.log("error", error)
        }
    }

    const goToFavPage = () => {
        try {
            getFavourite();
            $('.favorites_list-id').show();
            $('#main-vid-des').hide();
        } catch (error) {

        }
    }

    const getFavourite = async () => {
        var self = this;
        try {
            var dataObj = {};
            const token = localStorage.getItem('token');

            if (token) {
                const id = localStorage.getItem('id');
                dataObj = {
                    user_id: id,
                    page: page,
                    pagesize: pagesize,
                    sort: { createdAt: -1 }
                }
                dataObj = JSON.stringify(dataObj);

                setshowFevLoader(true)
                var favData = await apiCallManager.callApi('get_all_fav_data', dataObj, 'POST', false, false);

                //////console.log("favData", favData)
                if (favData.status === 1) {
                    var produchList = favData.data //uniqBy(favData.data, '_id');
                    // console.log("produchList", produchList)
                    setfavproduchList(produchList)
                    settotalViews(produchList.length)
                    setshowFevLoader(false)
                } else if (favData.status === 401) {
                    closeAllPopup();
                    // props.history.push(`/login`)
                    setopenLoginPop(true);
                } else {

                }
            } else {
                closeAllPopup();
                // props.history.push(`/login`)
                setopenLoginPop(true);
            }

        } catch (error) {
            ////console.log("error", error)
        }
    }

    const hideFavList = () => {
        try {
            $('.favorites_list-id').hide();
            $('#main-vid-des').show();
        } catch (error) {

        }
    }

    const removeMakeFav = async (data, index, flag) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                //console.log("dddd", data,index, flag)
                var dataObj = {
                    //uid: id,
                    fid: data._id
                }

                dataObj = JSON.stringify(dataObj);

                var deepCopy = JSON.parse(JSON.stringify(favproduchList))

                var res = await apiCallManager.callApi('remove_fav', dataObj, 'POST', false, false);
                if (res.status === 1) {

                    if (flag) {
                        var newSplu = deepCopy.splice(index, 1);
                        var index = findIndex(produchListBack, { _id: data.product_id })
                    }

                    //console.log("index", index)
                    produchListBack[index]['fev_data'] = null;
                    var deepBack = JSON.parse(JSON.stringify(produchListBack))

                    setfavproduchList(deepCopy)
                    setshowFevLoader((deepCopy.length === 0) ? false : true)
                    setProduchList(produchListBack)
                    setproduchListBack(deepBack)
                    setprodData(produchListBack[index])

                } else {
                    props.alert.error(res.message)
                }
            } else {
                setopenLoginPop(true);
                setopenSignUpPop(false);
                setopenForgotPop(false);
            }
        } catch (error) {
            //console.log("error11", error)
        }
    }

    const favProfuctListFn = (
        <div>
            {
                (favproduchList && favproduchList.length > 0) && favproduchList.map((item, index) => {
                    // console.log("item", item)
                    if (item.products) {
                        return (
                            <div key={index} className="favorites_list-item">
                                <div className="favorites_list-item-img">
                                    {
                                        (item.products && item.products.product_image) ?
                                            <ImagePlaceholder product_image={item.products.product_image} image_type={'product_image'} placeholder_image={`${frontUrl}product-placeholder.jpg`} placeholderClass={'fav-product-placeholder'} />
                                            :
                                            <div className="fav-product-placeholder"></div>
                                    }
                                </div>

                                <div className="favorites_list-item-info">
                                    <div className="favorites_list-item-title" onClick={showProdDetailPop.bind(this, true, item, index)}>{Capitalize(item.products.product_name)}</div>
                                    <div className="favorites_list-item-tray">
                                        <div className="favorites_list-item-tray-price">{currency}{item.products.price}</div>
                                        <div className="favorites_list-item-tray-logo">
                                            {
                                                (item.brands && item.brands.length > 0 && item.brands[0].image) ?
                                                    <ImagePlaceholder product_image={item.brands[0].image} image_type={'brand_image'} placeholderClass={'logo-placeholder'} placeholder_image={`${frontUrl}logo-placeholder.jpg`} />
                                                    :
                                                    <div className="logo-placeholder"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <a onClick={removeMakeFav.bind(this, item, index, true)} className="favorites_list-item-icon favlistdiff" style={{ cursor: "pointer" }}>
                                    <img src="https://api.viktrs.com/public/widget/images/favorite-fill-icon.svg" />
                                </a>
                            </div>
                        )
                    } else {

                    }
                })
            }
        </div>
    )

    return (
        <div className="viktrs-video-flow-megaparent" >
            <_Header tokenstr={tokenstr} goToFavPage={goToFavPage} clickLogout={clickLogout} />

            <div className="favorites_list favorites_list-id" style={{ "display": "none" }}>

                {
                    (favproduchList && favproduchList.length > 0) &&
                    <div className="favorites_list-title"><span style={{ "float": "left", "cursor": "pointer" }} onClick={hideFavList.bind(this)}>&#10094; Back</span> favorite item</div>
                }

                <div className="favorites_list-inner">
                    {
                        (favproduchList && favproduchList.length > 0) ?
                            favProfuctListFn
                            :
                            (showFevLoader) ?
                                <div className="favvo_loader">
                                    <span style={{ "float": "left", "cursor": "pointer" }} onClick={hideFavList.bind(this)}>&#10094; Back</span>
                                    {/* <img src="https://api.viktrs.com/public/widget/images/jabri-loader-diff.svg" width="30"></img> */}
                                </div>
                                :
                                <div className="favorites_list-title"><span style={{ "float": "left", "cursor": "pointer" }} onClick={hideFavList.bind(this)}>&#10094; Back</span>Nothing is in favorite list</div>
                    }
                </div>
            </div>
            <div id="main-vid-des">
                {
                    (showPop) &&
                    <div className="vik-fb-newpopup">
                        <div className="vik-fb-newpopup-inner">
                            <div className="vik-fb-newpopup-text">Welcome to viktrs! Save all your favourite items from this video to come back to later. For more information and to learn about terms & privacy go to https://www.viktrs.com/terms-of-use</div>
                            <button className="vik-fb-newpopup-btn" onClick={closePop.bind(this)}>ok</button>
                        </div>
                    </div>
                }
                {
                    (openLoginPop) ?
                        <div className="vikauth-megaparent">
                            <div className="vikauth-wrapper">
                                <div className="vikauth-close" onClick={closeAllPopup.bind(this)}>&#10005;</div>
                                <div className="vikauth-title">Login</div>
                                <div className="vikauth-inner">
                                    <form onSubmit={handleSubmitlogin} method="post">
                                        <div className="vikauth-field">
                                            <label>Email</label>
                                            <input type="email" placeholder="Email Address" name="email" value={values.email} onChange={handleChange('email')} onPaste={handlePaste} />
                                            {validator.current.message('email', values.email, 'required|email', { className: 'vikauth-error text-danger' })}
                                        </div>
                                        <div className="vikauth-field">
                                            <label>Password</label>
                                            <input type="password" placeholder="Password" value={values.password} name="password" onChange={handleChange('password')} />
                                            {validator.current.message('password', values.password, 'required|min:0', { className: 'vikauth-error text-danger' })}
                                        </div>
                                        <div className="vikauth-forgot" onClick={clickForgetPass.bind(this)}>
                                            Forgot Password
                                        </div>
                                        <div className="vikauth-btn-wrap">
                                            <button className="vikauth-btn" type="submit" style={{ "cursor": "pointer" }}>
                                                {
                                                    (showLoader) ?
                                                        <img src="https://api.viktrs.com/public/widget/images/jabri-loader-diff.svg" width="30"></img>
                                                        :
                                                        <span>Login</span>
                                                }

                                            </button>
                                            <div className="vikauth-or">Or</div>
                                            <div className="vikauth-btn vikauth-fbtn" onClick={facebookLoginClick} style={{ "cursor": "pointer" }}>
                                                <img src="https://api.viktrs.com/public/widget/images/fb-login-icon.svg" />
                                                <span>Log in with Facebook </span>
                                            </div>
                                            <a onClick={goToRegister.bind(this)} className="vikauth-sign-link">Sign up</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        :
                        (openSignUpPop) ?
                            <div className="vikauth-megaparent">
                                <div className="vikauth-wrapper">
                                    <div className="vikauth-close" onClick={closeAllPopup.bind(this)}>&#10005;</div>
                                    <div className="vikauth-title">Signup</div>
                                    <div className="vikauth-inner">
                                        <form onSubmit={handleSubmitregister}>
                                            <div className="vikauth-field">
                                                <label>Full Name</label>
                                                <input type="text" placeholder="Full name" value={values.fullname} name="fullname" onChange={handleChange('fullname')} />
                                                {validator.current.message('fullname', values.fullname, 'required', { className: 'text-danger' })}
                                            </div>
                                            <div className="vikauth-field">
                                                <label>Email</label>
                                                <input type="email" placeholder="Email Address" value={values.email} name="email" onChange={handleChange('email')} />
                                                {validator.current.message('email', values.email, 'required|email', { className: 'text-danger' })}
                                            </div>
                                            <div className="vikauth-field" style={{ marginBottom: "35px" }}>
                                                <label>Password</label>
                                                <input type="password" placeholder="Password" value={values.password} name="password" onChange={handleChange('password')} />
                                                {validator.current.message('password', values.password, 'required|min:0|max:12', { className: 'text-danger' })}
                                            </div>
                                            <button style={{ "marginTop": "50px" }} className="vikauth-btn" type="submit" style={{ "cursor": "pointer" }}>
                                                {
                                                    (showLoader) ?
                                                        <img src="https://api.viktrs.com/public/widget/images/jabri-loader-diff.svg" width="30"></img>
                                                        :
                                                        <span>Get Started</span>
                                                }
                                            </button>
                                            <div className="vikauth-or">Or</div>
                                            <div className="vikauth-btn vikauth-fbtn" onClick={facebookLoginClick} style={{ "cursor": "pointer" }}>
                                                <img src="https://api.viktrs.com/public/widget/images/fb-login-icon.svg" />
                                                Sign up with Facebook
                                            </div>
                                            <a onClick={backToLogin.bind(this)} className="vikauth-sign-link"><span>Already Registered?</span> Login</a>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            : (openForgotPop) ?
                                <div className="vikauth-megaparent">
                                    <div className="vikauth-wrapper">
                                        <div className="vikauth-close" onClick={closeAllPopup.bind(this)}>&#10005;</div>
                                        <div className="vikauth-title">Forgot Password</div>
                                        <div className="vikauth-inner">
                                            <form onSubmit={handleSubmitforgot}>
                                                <div className="vikauth-field">
                                                    <label>Email</label>
                                                    <input type="email" placeholder="Email Address" name="email" value={values.email} onChange={handleChange} />
                                                    {validator.current.message('email', values.email, 'required|email', { className: 'text-danger' })}
                                                </div>
                                                <button style={{ "marginTop": "30px" }} className="vikauth-btn" type="submit">

                                                    {
                                                        (showLoader) ?
                                                            <img src="https://api.viktrs.com/public/widget/images/jabri-loader-diff.svg" width="30"></img>
                                                            :
                                                            <span>Submit</span>
                                                    }
                                                </button>
                                            </form>

                                            <div className="vikauth-sign-link" onClick={backToLogin.bind(this)}>Back to login</div>

                                        </div>
                                    </div>
                                </div>
                                : null
                }
                {
                    (showViktrs) ?
                        <div className="viktrs-video-flow-parent">
                            {
                                (vidfullDescActive) &&
                                <div className="viktrs-video-desc-deskpop">
                                    <a onClick={videDescSeeMore.bind(this, false)} className="viktrs-video-desc-deskpop-close">
                                        <img src="/images/prodrop_cross.svg" />
                                    </a>
                                    <div className={`viktrs-video-desc ${(openVidDesc) ? "active" : ""}`}>
                                        <div dangerouslySetInnerHTML={{ __html: videoDetail.video_description }}></div>
                                    </div>
                                </div>
                            }
                            <div className="viktrs-video-flow">

                                {/* <div className="video" ref={c => (this.vid = c)} > </div> */}
                                {/* <Flowplayer token={flowToken} src="https://cdn.flowplayer.com/5389a9b8-b86b-4918-a835-16c12622a7e0/hls/25e1c73b-5079-4c19-9803-a10ccb20586d/playlist.m3u8" /> */}
                                
                                <Flowplayer
                                    src={videoUrl}
                                    // muted="false"
                                    // autoplay="true"
                                    ratio='960:400'
                                    poster={videoPoster}
                                    cuepoints={cuepointsArray}
                                    draw_cuepoints="true"
                                    mute="false"
                                />

                                {/* <button type="button" id="intiFlowplayer" onClick={intiFlowplayer.bind(this)}>click</button> */}
                                {/* <button type="button" onClick={destroyplayerdd.bind(this)}>Destroy</button> */}
                                {/* https://cdn.flowplayer.com/5389a9b8-b86b-4918-a835-16c12622a7e0/v-25e1c73b-5079-4c19-9803-a10ccb20586d.mp4 */}

                            </div>
                            <div className="viktrs-video-info viktrs-video-info-desktop">
                                <div className="viktrs-video-artist-wrap">
                                    <div className="viktrs-video-artist">
                                        <span className="viktrs-video-artist-avatar">
                                            {
                                                (videoDetail && videoDetail.artists_data) &&
                                                <ImagePlaceholder product_image={videoDetail.artists_data.image} image_type={'artist_image'} placeholderClass={'artist-placeholder'} placeholder_image={`${frontUrl}artist-placeholder.jpg`} />


                                            }
                                        </span>
                                        <span>{Capitalize((videoDetail.artists_data) ? videoDetail.artists_data.artist_name : null)}</span>
                                    </div>
                                    <div className="viktrs-video-name" title={Capitalize(videoDetail.video_name)}>{Capitalize(videoDetail.video_name)}
                                        <a className={`${(openVidDesc) ? "active" : ""}`} onClick={openVideoDesc.bind(this)}><img src="/images/vik-desc-icon.svg" /></a>
                                    </div>
                                    <div className="viktrs-video-analtic">
                                        <div className="viktrs-video-views">
                                            <img src="/images/vik-like-icon.svg" />
                                            <span>{totalInteraction}</span>
                                        </div>
                                        <div className="viktrs-video-views">
                                            <img src="/images/vik-view-icon.svg" />
                                            <span>{total_views}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={`viktrs-video-desc ${(openVidDesc) ? "active" : ""}`} style={{ height: "44px", overflow: "hidden", position: "relative" }}>
                                    <div dangerouslySetInnerHTML={{ __html: videoDetail.video_description }}></div> 
                                    {
                                        
                                        (videoDetail.video_description && videoDetail.video_description.length > 100) &&
                                        <span className="black-span" onClick={videDescSeeMore.bind(this, true)}>See more</span>
                                    }
                                </div>

                            </div>
                            <div className="viktrs-video-info viktrs-video-info-mobile">
                                <div className="viktrs-video-name">{Capitalize(videoDetail.video_name)}
                                    {
                                        (videoDetail.video_description && videoDetail.video_description.length > 0) &&
                                        <a className={`${(openVidDesc) ? "active" : ""}`} onClick={openVideoDesc.bind(this)}><img src="/images/vik-desc-icon.svg" /></a>
                                    }

                                </div>
                                <div className={`viktrs-video-desc ${(openVidDesc) ? "active" : ""}`} style={{ "zIndex": 999 }}>
                                    <div dangerouslySetInnerHTML={{ __html: videoDetail.video_description }}></div>

                                </div>
                                <div className="viktrs-video-artist-wrap">
                                    <div className="viktrs-video-artist">
                                        <span className="viktrs-video-artist-avatar">
                                            {
                                                (videoDetail && videoDetail.artists_data) &&
                                                <ImagePlaceholder product_image={videoDetail.artists_data.image} image_type={'artist_image'} placeholderClass={'artist-placeholder'} placeholder_image={`${frontUrl}artist-placeholder.jpg`} />
                                            }
                                        </span>
                                        <span>{Capitalize((videoDetail.artists_data) ? videoDetail.artists_data.artist_name : null)}</span>
                                    </div>
                                    <div className="viktrs-video-analtic">
                                        <div className="viktrs-video-views">
                                            <img src="/images/vik-like-icon.svg" />
                                            <span>{totalInteraction}</span>
                                        </div>
                                        <div className="viktrs-video-views">
                                            <img src="/images/vik-view-icon.svg" />
                                            <span>{total_views}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* {
                            (!showProdDetail)?                 */}
                            <div className="flowvideo-tab-list">
                                <div className={`vik-prodpop ${(showProdDetail) ? "vik-prodpop-active" : ""}`} >
                                    <div className="vik-prodpop-wrap">
                                        <div className={`vik-prodpop-full-description ${(fullDescActive) ? "vik-prodpop-full-description-active" : ""}`}>
                                            <div className="vik-prodpop-full-desc-inner">
                                                <div className="vik-prodpop-full-desc-close-parent">
                                                    <a onClick={closeShowMore.bind(this, prodData._id)} className="vik-prodpop-full-desc-close"><img src="/images/prodrop_cross.svg" /></a>
                                                </div>
                                                <div className="vik-prodpop-details-title">PRODUCT DETAILS</div>
                                                <div className="vik-prodpop-details-desc vik-prodpop-details-desc_diff">{fullDesc}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="vik-prodpop-inner">
                                                <div className="vik-prodpop-left">
                                                    <button className="vik-prodpop-back" onClick={showProdDetailPop.bind(this, false, prodData)}>
                                                        <img src="/images/arrowback.svg" />
                                                    </button>
                                                    <div onClick={goToProductDetailPage.bind(this, prodData)}>
                                                        {
                                                            (prodData && prodData.product_image) ?
                                                                <ImagePlaceholder classdata={'vik-prodpop-img'} product_image={prodData.product_image} image_type={'product_image'} placeholderClass={'product-detail-placeholder'} placeholder_image={`${frontUrl}product-placeholder.jpg`} />
                                                                :
                                                                <div className="product-detail-placeholder"></div>
                                                        }
                                                    </div>
                                                    {/* <a href="javascript:void(0)"><img src="/images/prod-favicon.svg" /></a> */}

                                                </div>
                                                <div className="vik-prodpop-right">
                                                    <div className="vik-prodpop-right-top">
                                                        <div className="vik-prodpop-title" onClick={goToProductDetailPage.bind(this, prodData)}>{Capitalize(prodData.product_name)}</div>
                                                        <div className="vik-prodpop-logo" onClick={goToProductDetailPage.bind(this, prodData)}>
                                                            {
                                                                (prodData.product_brand && prodData.product_brand.image) ?
                                                                    <ImagePlaceholder product_image={prodData.product_brand.image} image_type={'brand_image'} placeholderClass={'logo-placeholder'}
                                                                        placeholder_image={`${frontUrl}logo-placeholder.jpg`} />
                                                                    :
                                                                    <div className="logo-placeholder"></div>
                                                            }
                                                            {/* <div className="logo-placeholder"></div> */}
                                                        </div>

                                                        {
                                                            (prodData.currency == 'POA') ?
                                                                <div className="vik-prodpop-price" onClick={goToProductDetailPage.bind(this, prodData)}>{prodData.currency}</div>
                                                                :
                                                                <div className="vik-prodpop-price" onClick={goToProductDetailPage.bind(this, prodData)}><span>{prodData.currency}</span>{!isZero(prodData.price)?prodData.price:''}</div>
                                                        }


                                                        {/* <button className="vik-prodpop-morelink" onClick={openMoreDetail.bind(this)}>{(prodMoreDetail)?'Less Details':'More Details'}<img src="/images/vik-desc-icon.svg" /></button> */}
                                                    </div>
                                                    <div className="vik-prodpop-right-bottom">

                                                        <a href={prodData.product_website_url} onClick={shopNowButtonClick.bind(this, prodData._id)} target="_blank">
                                                            <button style={{ "marginTop": "0px" }} className="vik_shopnow">Shop Now</button>
                                                        </a>
                                                        {
                                                            // (prodData.is_fav) ?
                                                            //     <a onClick={makeFavFn.bind(this, prodData._id, videoId, false)}>
                                                            //         <img src="/images/favorite-fill-icon.svg" />
                                                            //     </a>
                                                            //     :
                                                            //     <a onClick={makeFavFn.bind(this, prodData._id, videoId, true)}>
                                                            //         <img src="/images/favorite-icon.svg" />
                                                            //     </a>
                                                        }

                                                        {/* <_HeartComponent
                                                        onPressFav={onPressFav.bind(this)}
                                                        oncallBack={onResponseCallback.bind(this)}
                                                        isFav={prodData.is_fav}
                                                        vid={videoId}
                                                        pid={prodData._id}
                                                        item={prodData}
                                                        index={prodIdx}
                                                    /> */}

                                                    </div>
                                                </div>

                                                {/* ref={(el) => { messagesEnd = el; }} */}
                                                {

                                                    <div className="vik-prodpop-details">
                                                        <div className="vik-prodpop-details-title">PRODUCT DETAILS</div>
                                                        <div className="vik-prodpop-details-desc vik-prodpop-details-desc_diff">
                                                            {checkSeeMore(prodData.description, 300)}
                                                            {
                                                                (prodData.description && prodData.description.length > 300) &&
                                                                <a onClick={clickShowMore.bind(this, prodData.description)}>See More</a>
                                                            }

                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-list">
                                    <div className={`tab-list-item tab-list-item-1 ${(activeTab == 'tab1') ? "tab-list-active" : ""}`} onClick={tabCLick.bind(this, 'tab1', 'product_tab_click')}>
                                        <div className="tab-list-item_inn">
                                        <div className="tab-list-item-imgwrap">
                                            <img src="/images/tab-list-icon-one-hover.svg" />
                                        </div>
                                        <span>Product</span>
                                        </div>
                                    </div>
                                    <div className={`tab-list-item tab-list-item-2 ${(activeTab == 'tab2') ? "tab-list-active" : ""}`} onClick={tabCLick.bind(this, 'tab2', 'people_tab_click')}>
                                        <div className="tab-list-item_inn">
                                        <div className="tab-list-item-imgwrap">
                                            <img src="/images/tab-list-icon-two-hover.svg" />
                                        </div>
                                        <span>People</span>
                                        </div>
                                    </div>
                                    <div className={`tab-list-item tab-list-item-3 ${(activeTab == 'tab3') ? "tab-list-active" : ""}`} onClick={tabCLick.bind(this, 'tab3', 'gigs_and_music_tab_click')}>
                                        <div className="tab-list-item_inn">
                                        <div className="tab-list-item-imgwrap">
                                            <img src="/images/tab-list-icon-three-hover.svg" />
                                        </div>
                                        <span>Gigs+Music</span>
                                        </div>
                                    </div>
                                    <div className={`tab-list-item tab-list-item-4 ${(activeTab == 'tab4') ? "tab-list-active" : ""}`} onClick={tabCLick.bind(this, 'tab4', 'inspiration_tab_click')}>
                                        <div className="tab-list-item_inn">
                                        <div className="tab-list-item-imgwrap">
                                            <img src="/images/tab-list-icon-four-hover.svg" />
                                        </div>
                                        <span>Inspirations</span>
                                        </div>
                                    </div>
                                    <div className="tab-list-item-line"></div>
                                </div>

                                {/* <ProductTab listenScrollEvent={listenScrollEvent.bind(this)} videoId={videoId} produchList={produchList} showProdDetailPop={showProdDetailPop.bind(this)} makeFavFn={makeFavFn.bind(this)}/> */}

                                {
                                    //(activeTab == 'tab1') &&
                                    <div className="viktrs-tabs" id="tab-dis-tab1">
                                        <div className="tab-product-wrapper" onScroll={listenScrollEvent.bind(this)}>
                                            {
                                                (produchList && produchList.length > 0) ? produchList.map((item, idpx) =>
                                                    <ProductTab key={idpx} idpx={idpx} videoId={videoId} item={item} showProdDetailPop={showProdDetailPop.bind(this)} makeFavFn={makeFavFn.bind(this)} />
                                                ) :  <div style={{ "textAlign": "center", "marginTop": "20px" }}>
                                                        <img src="https://api.viktrs.com/public/widget/images/ins_no_data.png" width="135" />
                                                    </div>}

                                        </div>
                                    </div>
                                }
                                {
                                    //(activeTab == 'tab2') &&
                                    <div className="viktrs-tabs" id="tab-dis-tab2" style={{ "display": "none" }}>
                                        <div className="tab-people-wrapper">
                                            {
                                                (peropleList && peropleList.length > 0) ? peropleList.map((item, idtx) =>
                                                    <PeopleTab key={idtx} idtx={idtx} item={item} clickPeopleClick={clickPeopleClick.bind(this)} />
                                                ) :  <div style={{ "textAlign": "center", "marginTop": "20px" }}>
                                                        <img src="https://api.viktrs.com/public/widget/images/ins_no_data.png" width="135" />
                                                    </div>}

                                        </div>
                                    </div>
                                }
                                {
                                    //(activeTab == 'tab3') &&
                                    <div className="viktrs-tabs" id="tab-dis-tab3" style={{ "display": "none" }}>
                                        <div className="tab-gig-wrapper" onScroll={listenScrollEvent.bind(this)}>
                                            <div>
                                                {
                                                    (gigsUrls && gigsUrls.length > 0) ? gigsUrls.map((item, idgx) =>
                                                        <MusicTab key={idgx} item={item} clickGigLinkClick={clickGigLinkClick.bind(this)} />
                                                    ) :  <div style={{ "textAlign": "center", "marginTop": "32px" }}>
                                                            <img src="https://api.viktrs.com/public/widget/images/ins_no_data.png" width="135" />
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    //(activeTab == 'tab4') &&
                                    <div className="viktrs-tabs" id="tab-dis-tab4" style={{ "display": "none" }}>
                                        <div className="tab-charity-wrapper" onScroll={listenScrollEvent.bind(this)} style={{ "overflow": "auto" }}>

                                            {
                                                (inspirationsList && inspirationsList.length > 0) ? inspirationsList.map((item, idix) =>
                                                    <InspirationTab key={idix} item={item} />

                                                )
                                                    :
                                                    <div style={{ "textAlign": "center", "marginTop": "12px" }}>
                                                        <img src="https://api.viktrs.com/public/widget/images/ins_no_data.png" width="135" />
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                }
                            </div>
                            {/* : */}

                            {/* } */}

                        </div>
                        :
                        (isPublish) ?
                            <div className="" >

                                <div style={{ textAlign: "center", fontSize: "27px", fontWeight: "bolder", marginTop: "50px", color: "#afaaaa" }}>
                                    <div className="vikauth-title" style={{ textAlign: "center" }}><span onClick={goToHome.bind(this)} style={{ float: "left", cursor: "pointer" }}>&#10094; Back</span></div>
                                    Video is not published</div>

                            </div>
                            :
                            <div>
                                <div className="vik-loaderdiv" >
                                    <img className="vik-loaderimg" src="https://api.viktrs.com/public/loading2.gif" width="50px" />
                                </div>
                            </div>
                }
            </div>
        </div>
    );

}

const mapStateToProps = state => ({
    is_authincate: state.is_authincate
});

const mapDispatchToProps = dispatch => ({
    setAuth: (data) => dispatch(setAuth(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Video);

// export default Video;
