import React, { useState, useEffect, useRef } from 'react';
import { apiBaseUrl, currency, frontUrl } from './config.js';

const ImagePlaceholder = (props) => {

    const [loaded, setloaded] = useState(false);

    const setIMg = (img, flag) => {
        try {
            // ////console.log("imgg", img)
            if(flag && flag == 'flow_player_cdn'){
                return img;
            }
            if (img && flag && img.includes(flag)) {
                var imgg = img.split('/').pop();
                return frontUrl + imgg;
            } else if (img && img != null && img != 'null') {
                return frontUrl + img;
            } else {
                return apiBaseUrl + '/public/no-image.jpg';
            }
        } catch (error) {

        }
    }

    const imgCLick = (flag, item, id) => {
        try {
            console.log('1111111')
            try {
                props.showProdDetailPop(flag, item, id)
            } catch (error) {
                
            }
            
        } catch (error) {

        }
    }
    return (
        <>
            
            {loaded ? null :
                <div className={props.placeholderClass}></div>
            }

            <img
                style={props.style_data}
                onClick={() => { imgCLick(true, props.item, props.idx) }}
                className={props.classdata} src={setIMg(props.product_image, props.image_type)}
                alt="Viktrs"
                style={loaded ? {} : { display: 'none' }}
                onError={(e) => {
                    e.target.src = props.placeholder_image // some replacement image
                    // e.target.style = 'padding: 8px; margin: 16px' // inline styles in html format
                    e.target.className = props.placeholderClass // inline styles in html format
                }}
                onLoad={()=>{setloaded(true)}}
            />


        </>
    )
}


export default ImagePlaceholder;