import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import apiCallManager from '../../common/helper.js';
import { apiBaseUrl, currency, frontUrl } from '../../common/config.js';
import _HeartComponent from '../../common/_HeartComponent';
import ImagePlaceholder from '../../common/ImagePlaceholder';

const PeopleTab = (props) => {

    // const [produchList, setProduchList] = useState(props.produchList);

    const setIMg = (img, flag) => {
        try {
            // ////console.log("imgg", img)
            if (img && img.includes(flag)) {
                var imgg = img.split('/').pop();
                return frontUrl + imgg;
            } else if (img && img != null && img != 'null') {
                return frontUrl + img;
            } else {
                return apiBaseUrl + '/public/no-image.jpg';
            }
        } catch (error) {

        }
    }

    const clickPeopleClick = (item) => {
        console.log("******", "clickPeopleClick", props.item)
        props.clickPeopleClick(item)
    }

    const Capitalize = (str, num) => {
        try {
            if (str) {
                return str //str.charAt(0).toUpperCase() + str.slice(1);
            } else {
                return null
            }
        } catch (error) {

        }
    }

    return (
        <>
            <div style={{ cursor: "pointer" }} onClick={() => { clickPeopleClick(props.item) }} className="tab-people-item">
                {
                    (props.item && props.item.image) &&
                    <div className="tab-people-avatar">
                        {/* <img src={setIMg(props.item.image, 'people_image')} style={{ "maxWidth": "85px" }} /> */}
                        <ImagePlaceholder product_image={props.item.image} image_type={'people_image'} placeholderClass={'people-placeholder'} 
                        style_data={{"maxWidth": "85px"}} placeholder_image={`${frontUrl}people-placeholder.jpg`} />
                    </div>
                }

                <div className="tab-people-details">
                    <div className="tab-people-name"><span>{Capitalize(props.item.name)}</span></div>
                    <div className="tab-people-artist">{Capitalize(props.item.role)}</div>
                </div>
                {
                    (props.item.id && props.item.id == 'instagram' && props.item.link) ?
                        <div className="tab-people-social"><img src="/images/instagram.svg" /></div>
                        :
                        (props.item.id && props.item.id == 'twitter' && props.item.link) ?
                            <div className="tab-people-social"><img src="/images/twitter.svg" /></div>
                            : (props.item.id && props.item.id == 'facebook' && props.item.link) ?
                                <div className="tab-people-social"><img src="/images/facebook.svg" /></div>
                                : (props.item.id && props.item.id == 'links' && props.item.link) ?
                                    <div className="tab-people-social"><img src="/images/glob.png" height="25" width="25" /></div>
                                : (props.item.id && props.item.id == 'tiktok' && props.item.link) ?
                                    <div className="tab-people-social"><img src="/images/tiktok.svg"  width="50" /></div>
                                    :
                                    null
                }

            </div>
        </>
    )
}

/**
 * Connection with Redux
 */
// const mapStateToProps = state => ({
//     myname: state.myname
// });

// const mapDispatchToProps = dispatch => ({
//     addName: (data) => dispatch(addName(data)),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Favourite);

export default PeopleTab;
