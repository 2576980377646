import React from 'react'
// import {Link} from 'react-router-dom'; 
// import SVG from 'react-inlinesvg';
import cookie from 'react-cookies';
// import { connect } from 'react-redux';
import apiCallManager from './helper.js';
import _ from 'lodash';

class _HeartComponent extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			liked: this.props.isFav,
			isFav : this.props.isFav
		}
	
		this.lastPress = 0
		
	}

	async componentDidMount() {
		var self = this;
		try{		
			// console.log("isFav", this.state.isFav)
		}catch(error){
			//console.log("error1111", error)
		}
	}

	screenDidMount() {
		var self = this;
		try {

		} catch (error) {
			//console.log("error1111", error)
		}
	}

	screenWillBlur() {
		try {
			
		} catch (error) {
			//console.log("error2222222", error)
		}
	}

	componentWillUnmount() {
	}

	handleLargeAnimatedIconRef = (ref) => {
		this.largeAnimatedIcon = ref
	}
	
	handleSmallAnimatedIconRef = (ref) => {
		this.smallAnimatedIcon = ref
    }
    
	handleOnPressLike = () => {
		try {
			
		} catch (error) {
			//console.log("error", error)	
		}		
	}

	addFevAnimation(){
		var self = this;
		try{
			if(this.props.userInfo){
				this.props.onPressFav('is_fav', true, self.props.index, this.props.index);  
				let tempArray = JSON.parse(JSON.stringify(self.props.serviceList));
				tempArray.data[self.props.index]['fev_data'].push({"_id": "res.data"});
				self.props.updateServiceList(tempArray)
				this.setState({
						isFav : true,
						isPlaying : false
				}, function() {
					var dataObj = {
							"service_id": self.props.service_id,
							"is_fev": JSON.stringify(self.state.isFav),
							"localization" : self.props.currentLanguage
					};
					////console.log("*******Adddd", dataObj)

					self.props.addDeleteFavorite(dataObj, function(res){
						//console.log("****Adddd resss", res)
						if(res.status === 1){
							//var tempArray = {...self.props.serviceList};
							let tempArray = JSON.parse(JSON.stringify(self.props.serviceList));
							tempArray.data[self.props.index]['fev_data'] = [{"_id": res.data}];
							////console.log("res********", self.props.index, tempArray.data)
							self.props.updateServiceList(tempArray)
							self.callCheckFevFn('add', res.data, tempArray.data[self.props.index]);   
						}                        
					});		
				});
			}else{
					this.props.onPressFav('login', null, this.props.index);
			}
		}catch(error){
				//console.log("error", error)
		}
    }

	removeFevAnimation(){
		var self = this;        
		try{
				
			if(this.props.fev_id){
					////console.log('111111')
					this.removeFev(this.props.fev_id);
			}else if(self.props.serviceList.data[self.props.index]['fev_data'].length > 0){
					////console.log('22222')
					this.removeFev(this.props.serviceList.data[self.props.index]['fev_data'][0]._id);                
			}            
		}catch(error){
				//console.log("error", error)
		}
	}

	removeFev(fevId){
		var self = this;
		try{            
			var gindx = _.findIndex(self.props.serviceList.data, { '_id': this.props.service_id });
			// //console.log("fevId", fevId, gindx, this.props.service_id)
			
			var dataObj = {
					"fev_id": fevId,
					"is_fev": 'false',
					"localization" : self.props.currentLanguage
			};
			////console.log("dataObj", dataObj)
			this.props.onPressFav('remove', false, this.props.index);
			let tempArray = JSON.parse(JSON.stringify(self.props.serviceList));
			tempArray.data[gindx]['fev_data'] = [];
			self.props.updateServiceList(tempArray);  
			self.props.addDeleteFavorite(dataObj, function(res){
				//console.log("******Remove", res)
				if(res.status === 1){									
						self.callCheckFevFn('remove', fevId, null);                  
				}                        
			});
		}catch(error){
				//console.log("error", error)
		}
	}

	callCheckFevFn(type, favId, item){
		var self = this;
		try{
			//console.log(favId, item)
			let tempArray = JSON.parse(JSON.stringify(self.props.favList));
			if(type == 'add'){
				var dataObj ={
					"_id": favId,
					"service_id": item._id,
					"service_data": item
				}
				
				tempArray.unshift(dataObj);
				this.props.setFavList(tempArray)
			}else if(type == 'remove'){
				var favIndex = _.findIndex(tempArray, {"_id": favId})
				//console.log("favIndex", favIndex)
				tempArray.splice(favIndex, 1);
				this.props.setFavList(tempArray)
			}
			// var dataObj = {
			// 		"page" : 1,
			// 		"pagesize" : APPCONSTANT.PAGE_SIZE,
			// 		"sort": {"createdAt":-1},
			// 		"localization" : this.props.currentLanguage,
			// }
			// this.props.getFavList(dataObj, function(res){
			// 	////console.log("callCheckFevFn()", res)
			// })  
		}catch(error){
			//console.log("getFavList", error)
		}
    }

    makeFav(){
        try {
            console.log("this.state.isFav", this.state.isFav)
            const token = cookie.load('token');
            if(token){
                this.setState({
                    isFav : !this.state.isFav
                },()=>{
                    this.makeFavFn(this.state.isFav)
                });
                
            }else{
				this.props.onPressFav('login', this.props.pid, this.props.index);
            }
        } catch (error) {
            console.log("error", error)
        }
    }
    
    async makeFavFn(favStatus){
        try {
            //console.log("item, index", this.props.vid, this.props.pid, this.props.index)
            const id = cookie.load('id');
            var dataObj = {
                //uid: id,
                vid: this.props.vid,
                pid: this.props.pid,
                fav_status : favStatus
            }

            dataObj = JSON.stringify(dataObj);
          
            // console.log("dataObj", dataObj)
			this.props.oncallBack(favStatus, this.props.index);
            var res = await apiCallManager.callApi('add_update_fav', dataObj, 'POST', false, false);
            if (res.status === 1) {
            }else{
                this.props.alert.error(res.message)
            }

        } catch (error) {
          console.log("error", error)
        }
    }

	render() {
		//const { navigation } = this.props;
		// console.log("this.state.isFav", this.state.isFav)
		const { liked } = this.state
		return (      
                <a onClick={this.makeFav.bind(this)}>
                    {
                        (this.state.isFav)?
                            <img src="/images/favorite-fill-icon.svg" />
                        :
                            <img src="/images/favorite-icon.svg" />
                    }                    
                </a>  		
		);
	}
}

/**
 * Connection with Redux
 */
const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
});

export default _HeartComponent
