import React from 'react'
import { connect } from 'react-redux';
import apiCallManager from '../../common/helper.js';
import { Redirect } from 'react-router';
import cookie from 'react-cookies';
import { Facebook } from '../../common/_facebook.js';
import ImagePlaceholder from '../../common/ImagePlaceholder';
import { frontUrl } from '../../common/config.js';

class Home extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            videoList: [],
            isLogout: true,
            isImageLoad: false
        }


    }

    componentDidMount() {
        // console.log("this.props.myname", this.props.myname)
        try {
            const tokens = cookie.load('token');
            const clickpopStatus = cookie.load('click_popup');
            // console.log("clickpopStatus", clickpopStatus)
            if (tokens && !clickpopStatus || clickpopStatus === false || clickpopStatus == 'false') {
                this.setState({
                    showPop: true
                });
            }
            this.getVideoList();
            window.addEventListener('resize', () => {
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            });
        } catch (error) {

        }
    }

    async getVideoList() {
        try {
            var dataObj = {
                page: 1,
                pagesize: 20,
                localization: "er",
                sort: { "createdAt": -1 }
            }
            dataObj = JSON.stringify(dataObj);

            var videoData = await apiCallManager.callApi('get_video_list', dataObj, 'POST', true, false);
            // console.log("videoData***", videoData)
            if (videoData.status === 1) {
                this.setState({
                    videoList: videoData.data
                });
                // let vh = window.innerHeight * 0.01;
                // document.documentElement.style.setProperty('--vh', `${vh}px`);
            }

        } catch (error) {
            console.log("error", error)
        }
    }

    getVideo(item) {
        try {
            // console.log(item)
            //return <Redirect to={{pathname: 'video'}} />
            this.props.history.push(`/video/${item._id}`)
        } catch (error) {
            console.log("error", error)
        }
    }

    async closePop() {
        try {
            this.setState({
                showPop: false
            })

            var dataObj = JSON.stringify({ click_popup: true });
            var res = await apiCallManager.callApi('update_user', dataObj, 'POST', false, false);

            if (res.status === 1) {
                localStorage.setItem('click_popup', true);
            }
        } catch (error) {

        }
    }

    clickLogout() {
        try {
            localStorage.removeItem('token', { path: '/' });
            localStorage.removeItem('id', { path: '/' });
            localStorage.removeItem('pid', { path: '/' });
            localStorage.removeItem('vid', { path: '/' });
            localStorage.removeItem('priv_path', { path: '/' });
            localStorage.removeItem('click_popup', { path: '/' });
            localStorage.removeItem('photo', { path: '/' });
            localStorage.removeItem('fullname', { path: '/' });
            // this.props.setAuth(false);
            // this.props.history.push(`/`)
            const login_type = localStorage.getItem('login_type');
            // console.log("login_type**", login_type)
            if (login_type && login_type == 'facebook') {
                Facebook.doLogout()
            }
            this.setState({
                isLogout: false
            });
            // alert('11')
            const token = localStorage.getItem('token');

            this.deleteAllCookies()
            // window.location.reload();
            // console.log("this.props.is_authincate", this.props.is_authincate, token)
        } catch (error) {
            console.log("error", error)
        }
    }

    deleteAllCookies() {
        try {
            const token = localStorage.getItem('token');
            localStorage.clear();

            setTimeout(function () {

                // console.log("token", token)
            });
        } catch (error) {
            console.log("error", error)
        }
    }

    showImage = () => {
        this.setState({ loaded: true });
    }

    render() {
        const token = localStorage.getItem('token');
        return (
            <div>
                <header className="header">
                    <div className="header-container">
                        <div className="header-logo">
                            <a href="https://www.viktrs.com" target="_blank"><img src="/images/viktrs-logo.svg" /></a>
                        </div>
                        {
                            (token) ?
                                <div>
                                    <div className="header-actions">
                                        {/* <a onClick={this.goToFavPage.bind(this)} className="head_favo_link"><SVG src="/images/favorite-icon.svg"></SVG><span>Favorites</span></a> */}
                                        <div className="logout-link" onClick={this.clickLogout.bind(this)}>Logout</div>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                </header>

                <div className="homepage">
                    {/* {
                        (this.state.showPop) &&
                        <div className="vik-fb-newpopup">
                            <div className="vik-fb-newpopup-inner">
                                <div className="vik-fb-newpopup-text">Welcome to viktrs! Save all your favourite items from this video to come back to later. For more information and to learn about terms & privacy go to https://www.viktrs.com/terms-of-use</div>
                                <button className="vik-fb-newpopup-btn" onClick={this.closePop.bind(this)}>ok</button>
                            </div>
                        </div>
                    } */}
                    <div className="container">
                        {
                            (this.state.videoList && this.state.videoList.length > 0) ? this.state.videoList.map((item, index) =>
                                <div key={index} className="card" onClick={this.getVideo.bind(this, item)}>
                                    {
                                        (item.video_obj && item.video_obj.poster) ?
                                            <ImagePlaceholder classdata={'card-img-top'} product_image={item.video_obj.poster} image_type={'flow_player_cdn'} placeholderClass={'card-placeholder'} placeholder_image={`${frontUrl}card-placeholder.jpg`}/>
                                            :
                                            <div className="card-placeholder"></div>
                                    }



                                    {/* <div className="card-placeholder"></div> */}
                                    <div className="card-body">
                                        <h5 className="card-title">{item.video_name}</h5>
                                        <p className="card-text">Artist Name : {item.video_name}</p>
                                    </div>
                                    {/* <Link className="btn btn-primary btn-sm" to={{pathname: 'video'}}>View</Link> */}
                                </div>
                            ) : null}

                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Connection with Redux
 */
const mapStateToProps = state => ({
    myname: state.myname
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
