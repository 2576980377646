import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Route} from 'react-router-dom'
import { Provider } from "react-redux";
import store from "./redux/store";
import { positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const options = {
  timeout: 2500,
  position: positions.TOP_CENTER
};

ReactDOM.render(  
    <Provider store={store}>
      <Router>  
        <AlertProvider template={AlertTemplate} {...options}>    
          <App />
          <ToastContainer hideProgressBar={true} transition={Slide} position="top-right" autoClose={2000} hideProgressBar={true} newestOnTop={false} closeOnClick={true} rtl={false}/>
        </AlertProvider>
      </Router>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();