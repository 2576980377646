import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import apiCallManager from '../../common/helper.js';
import { apiBaseUrl, currency, frontUrl } from '../../common/config.js';
import _HeartComponent from '../../common/_HeartComponent';
import ImagePlaceholder from '../../common/ImagePlaceholder';

const MusicTab = (props) => {

    // const [produchList, setProduchList] = useState(props.produchList);

    const setIMg = (img, flag) => {
        try {
            // ////console.log("imgg", img)
            if (img && img.includes(flag)) {
                var imgg = img.split('/').pop();
                return frontUrl + imgg;
            } else if (img && img != null && img != 'null') {
                return frontUrl + img;
            } else {
                return apiBaseUrl + '/public/no-image.jpg';
            }
        } catch (error) {

        }
    }

    const inspirationLinkClick = () => {
        console.log("******", "inspirationLinkClick")
        props.inspirationLinkClick()
    }

    const urlify = (text) => {
        var urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        return text.replace(urlRegex, function (url) {
            return '<a target="_blank" class="cap_href" href="' + url + '">' + url + '</a>';
        })
        // or alternatively
        // return text.replace(urlRegex, '<a href="$1">$1</a>')
    }

    return (
        <>
            <div style={{ "marginBottom": "10px" }}>
                {
                    (props.item.type == 'caption') ?
                        <div className="inspi-item capt">
                            <div className="inspi-capt" dangerouslySetInnerHTML={{ __html: urlify(props.item.caption) }}>
                            </div>
                        </div>
                        : (props.item.type == 'captionwithImage') ?
                            <div className="inspi-item capt-img">
                                <div className="inspi-wrap">
                                    <div className="inspi-img">
                                        <ImagePlaceholder product_image={props.item.image} image_type={'inspiraton_image'} placeholderClass={'inspire-placeholder'}
                                            placeholder_image={`${frontUrl}inspire-placeholder.jpg`} />
                                    </div>
                                    <div className="inspi-capt" dangerouslySetInnerHTML={{ __html: urlify(props.item.caption) }}>

                                    </div>
                                </div>
                            </div>
                            : (props.item.type == 'captionwithurlImg') ?
                                <div className="inspi-item capt-img-url">
                                    <div className="inspi-wrap">
                                        <div className="inspi-img">
                                            <ImagePlaceholder product_image={props.item.image} image_type={'inspiraton_image'} placeholderClass={'inspire-placeholder'}
                                            placeholder_image={`${frontUrl}inspire-placeholder.jpg`} />
                                        </div>
                                        <div className="inspi-capt">
                                            <span dangerouslySetInnerHTML={{ __html: urlify(props.item.caption) }}></span>
                                            {
                                                (props.item.url && props.item.url.length > 0) &&
                                                <a href={props.item.url} target="_blank"  className="inspi-url">Click Here</a>
                                            }
                                        </div>
                                    </div>
                                </div>
                                : null
                }

            </div>
        </>
    )
}

/**
 * Connection with Redux
 */
// const mapStateToProps = state => ({
//     myname: state.myname
// });

// const mapDispatchToProps = dispatch => ({
//     addName: (data) => dispatch(addName(data)),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Favourite);

export default MusicTab;
