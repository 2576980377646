import React from 'react'
import { connect } from 'react-redux';
import { addName } from "../../redux/action/index";

const _Header = (props) => {

    return (
        <header className="header">
            <div className="header-container">
                <div className="header-logo">
                    <a href="https://www.viktrs.com" target="_blank"><img src="/images/viktrs-logo.svg" /></a>
                </div>
                {
                    (props.tokenstr) &&
                    <div>
                        <div className="header-actions">
                            <a onClick={props.goToFavPage} className="head_favo_link" style={{ "cursor": "pointer" }}>
                                <img src="https://api.viktrs.com/public/widget/images/favorite-icon.svg" />
                                <span>Favorites</span>
                            </a>
                            <div className="logout-link" onClick={props.clickLogout}>Logout</div>
                        </div>
                    </div>
                }
            </div>
        </header>
    )
}

/**
 * Connection with Redux
 */
const mapStateToProps = state => ({
    myname: state.myname
});

const mapDispatchToProps = dispatch => ({
    addName: (data) => dispatch(addName(data)),
});

// export default connect(mapStateToProps, mapDispatchToProps)(Favourite);

export default connect(mapStateToProps, mapDispatchToProps)(_Header);