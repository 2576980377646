import { apiBaseUrl, secretKey } from './config.js';
import cookie from 'react-cookies';
var CryptoJS = require("crypto-js");

const apiCallManager = {
    callApi(url, dataObj, method, isPublic, isMultipart) {
        // console.log("apiCallManager")
        try {
            let headers = {};
            let response;
            let responseJSON;
            if (isPublic) {
                headers = { 'content-Type': 'application/json', 'Accept': 'application/json' };
            } else if (isMultipart) {
                headers = { 'Authorization': localStorage.getItem('token') };
            } else {
                headers = { 'content-Type': 'application/json', 'Authorization': localStorage.getItem('token') };
            }

            var urlapi = apiBaseUrl + '/api/' + url;

            return new Promise(async (resolve, reject) => {
                if (method == 'POST') {

                    // console.log(CryptoJS.HmacSHA1("Message", "Key"));
                    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(dataObj), secretKey).toString();
                    dataObj = JSON.stringify({ "bodyData": ciphertext })

                    // console.log("dataObj", dataObj)
                    const options = {
                        method: 'POST',
                        headers: headers,
                        body: dataObj
                    };
                    response = await fetch(urlapi, options);
                } else if (method == 'GET') {
                    response = await fetch(urlapi, { mathod: method, headers: headers });
                }
                if (response.status === 200) {
                    responseJSON = await response.json();
                    
                    if(responseJSON.data){
                        var resData = await this.checkEncryption(responseJSON.data);
                        responseJSON.data = resData;
                    }
                    
                    if(responseJSON.prodlist){
                        var prodlist = await this.checkEncryption(responseJSON.prodlist);
                        responseJSON.prodlist = prodlist;
                    }

                    // if(responseJSON.total_views){
                    //     var total_views = await this.checkEncryption(responseJSON.total_views);
                    //     responseJSON.total_views = total_views;
                    // }

                    // if(responseJSON.intractions){
                    //     var intractions = await this.checkEncryption(responseJSON.intractions);
                    //     responseJSON.intractions = intractions;
                    // }
                    
                    resolve(responseJSON)
                } else if (response.status === 401) {
                    resolve({ "status": 401 })
                } else {
                    // console.log("response", response)
                    reject(response)
                }
            })
        } catch (error) {
            console.log("error", error)
        }
    },
    async getIpAddr() {
        return new Promise(async (resolve, reject) => {
            let response;
            let headers = { 'content-Type': 'application/json', 'Accept': 'application/json' };
            let responseJSON;
            let urlapi = `https://ipapi.co/json/?key=Dl9KHxjOJXmQQIAxgHEfvcmVNiSqwJF8NCJRjEbYlqlYn1ZvTo`
            response = await fetch(urlapi, { mathod: 'GET', headers: headers });
            if (response.status === 200) {
                responseJSON = await response.json();
                resolve(responseJSON)
            } else if (response.status === 401) {
                resolve({ "status": 401 })
            } else {
                // console.log("response", response)
                reject(response)
            }
        })
    },
    async checkEncryption(bodyData) {
        return new Promise(async (resolve, reject) => {
            try {
                // console.log("bodyData", secretKey, "*****", bodyData)
                var bytes = CryptoJS.AES.decrypt(bodyData, secretKey);
                var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                // console.log("decryptedData", decryptedData)
                resolve(decryptedData);
            } catch (error) {
                console.log("error", error)
                reject({ status: 0, message: 'Api encription error' });
            }
        })
    }

}


export default apiCallManager

// module.exports = {
//     apiCallManager
// };
