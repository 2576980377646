import React from 'react';
// import logo from './logo.svg'; 
import './App.css';
import { Switch, Route, HashRouter, BrowserRouter } from 'react-router-dom';
import Footer from './component/Footer';
import HomePage from './component/Home';
import VideoPage from './component/Video';


function App() {
  // Facebook.checkStatus();
  return (
    <div className="viktrs-app">
      {/* <Header /> */}
      <Switch>
        <Route exact path="/" component={HomePage} />
        {/* <GuardedRoute path='/service' component={ServicePage} /> */}
        <Route exact path="/video/:id" component={VideoPage} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
