import { ADD_ARTICLE, ADD_NAME, IS_AUTHENTICATE } from "../constants/action-types";

export function addArticle(payload) {
  return { type: ADD_ARTICLE, payload };
}

export function addName(payload) {
  return { type: ADD_NAME, payload };
}

export function setAuth(payload) {
  return { type: IS_AUTHENTICATE, payload };
}